const movRequest = {
  fetch<T>(url: string, method: "GET", params?: any, platform?: string) {
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    if (platform) {
      apiHeaders.value["moveable-platform"] = platform;
    }

    return $fetch<T>(url, {
      baseURL: baseUrl.value,
      method: method,
      headers: apiHeaders.value,
      params: params
    });

  },

  get<T>(url: string, params?: any, platform?: string) {
    return this.fetch<T>(url, "GET", params, platform);
  },

  useFetch<T>(url: string, params?: any) {
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    return useFetch<T>(url, {
      baseURL: baseUrl.value,
      headers: apiHeaders.value,
      params: params,
    });
  },
};

export default movRequest;
